import React from "react";
import { Box, Typography } from "@mui/material";
import Carousel from "../carousel/Carousel";
import { One } from '../../apiaction/customKey/one';
import { Three } from '../../apiaction/customKey/Two/Three/Three';
import { Five } from '../../apiaction/customKey/Two/Three/Four/Five/Five';
import { Seven } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Seven';
import { Nine } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Nine';
import { Eleven } from '../../customHook/Eleven/Eleven';
import { Thirteen } from '../../customHook/Eleven/Twelve/Thirteen/Thirteen';
import { Fifteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Fifteen';
import { Seventeen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Seventeen';
import { Nineteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Nineteen/Nineteen';
import { TwentyOne } from '../../pages/Twenty/TwentyOne/TwentyoOne';
import { TwentyThree } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyThree';
import { TwentyFive } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentyFive';
import { TwentySeven } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentySeven';
import { TwentyNine } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/TwentyNine';
const Testimonials = ({homeDate ,getLocation, substoredEncryptedKey,mainTheme})=>{
    const suboriginalKey = One.concat(Three,Five,Seven,Nine,Eleven,Thirteen,Fifteen,Seventeen,Nineteen,TwentyOne,TwentyThree,TwentyFive,TwentySeven,TwentyNine);
    const backgroundStyle = getLocation.background_option === 1
    ? { backgroundColor: getLocation.testimonial_bg_color }
    : {
        backgroundImage: {
            xs: `url(${getLocation.testimonial_bg_image_medium})`,
            sm: `url(${getLocation.testimonial_bg_image_medium})`,
            md: `url(${getLocation.testimonial_bg_image})`,
            lg: `url(${getLocation.testimonial_bg_image})`
        }
      };
      if (substoredEncryptedKey == suboriginalKey) {
          return (
            <React.Fragment>
                {substoredEncryptedKey == suboriginalKey && (
                    <Box sx={{position:'relative'}}>
                        <Box id='Testimonials' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',width:'100%'}}></Box>
                        <Box
                        sx={{
                            height:'480px',
                            ...backgroundStyle,
                            // boxShadow:'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center',
                            backgroundPosition:'center',
                            backgroundSize:'cover',
                            backgroundRepeat:'no-repeat',
                            paddingTop:`45px`
                        }}
                        >
                            { homeDate.action == 'show' && (
                            <Box 
                                sx={{
                                    height:'20%',
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                <Typography 
                                    sx={{textAlign:'center'}}
                                    variant="h4" 
                                    dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                            </Box>
                            )}
                            <Box sx={{
                                    position:'relative',
                                    display:'flex',
                                    alignItems:'center',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    height:'100%',width:'100%',overflow:'hidden',
                                    paddingTop:`${mainTheme?.layout == 1 ? '150px': '' }`
                                    
                                }}>
                                    <Carousel type="TestimonialsCarousel" homeDate={homeDate} getLocation={getLocation} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                            </Box>
                        </Box>
                    </Box>
                )}
            </React.Fragment>
          )
      }else{
        return null;
      }
}
export default Testimonials;