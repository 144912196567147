import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
import { One } from '../../apiaction/customKey/one';
import { Three } from '../../apiaction/customKey/Two/Three/Three';
import { Five } from '../../apiaction/customKey/Two/Three/Four/Five/Five';
import { Seven } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Seven';
import { Nine } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Nine';
import { Eleven } from '../../customHook/Eleven/Eleven';
import { Thirteen } from '../../customHook/Eleven/Twelve/Thirteen/Thirteen';
import { Fifteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Fifteen';
import { Seventeen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Seventeen';
import { Nineteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Nineteen/Nineteen';
import { TwentyOne } from '../../pages/Twenty/TwentyOne/TwentyoOne';
import { TwentyThree } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyThree';
import { TwentyFive } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentyFive';
import { TwentySeven } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentySeven';
import { TwentyNine } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/TwentyNine';
const SplitView = ({homeDate, themeData, substoredEncryptedKey,mainTheme})=>{
    const suboriginalKey = One.concat(Three,Five,Seven,Nine,Eleven,Thirteen,Fifteen,Seventeen,Nineteen,TwentyOne,TwentyThree,TwentyFive,TwentySeven,TwentyNine);
    if (suboriginalKey == substoredEncryptedKey) {
        return(
        <React.Fragment>
            {substoredEncryptedKey == suboriginalKey && (
                <Box sx={{position:'relative'}}>
                    <Box id='Split_View' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',width:'100%'}}></Box>
                    <Box
                        sx={{
                            height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'},
                            backgroundColor:`${homeDate.data.bg_color}`,
                        }}
                        >
                        <Grid container sx={{marginTop:'0'}}>
                            {homeDate.data.settings==='right' && (
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box 
                                    sx={{
                                        height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'},
                                        display:'flex',
                                        flexDirection:'column',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        px:3,
                                        py:{xs:4,sm:4,md:1,lg:1},
                                        textAlign:'center'
                                    }}>
                                        { homeDate.action == 'show' && (
                                            <Typography 
                                            sx={{textAlign:'center'}}
                                            variant="h4" 
                                            dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                                        )}
                                        <Box sx={{my:1}}>
                                            <Typography variant="h5" sx={{textAlign:'center'}} dangerouslySetInnerHTML={{__html:homeDate?.data?.box_title}} />
                                        </Box>
                                        <Box sx={{my:1}}>
                                            <Typography variant="h5" sx={{textAlign:'center'}} dangerouslySetInnerHTML={{__html:homeDate?.data?.description}} />
                                        </Box>
                                        <Box sx={{my:1}}>
                                            {mainTheme?.layout == 5 && (
                                                <ButtonGroup
                                                    disableElevation
                                                    variant="contained"
                                                    aria-label="Disabled button group"
                                                >
                                                    <Button
                                                        sx={{
                                                            my:1.5,
                                                            backgroundColor:`${themeData?.button_color}`,
                                                            color:`${themeData?.button_font_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            fontWeight:600,
                                                            '&:hover': {
                                                            backgroundColor:`${themeData?.button_font_color}`,
                                                            color:`${themeData?.button_color}`,
                                                            border:`1px solid ${themeData?.button_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            }
                                                        }}
                                                        href={`${homeDate?.data?.button_url}`}
                                                    >
                                                        <Typography className="btn_text" dangerouslySetInnerHTML={{ __html: homeDate?.data?.button }}/>
                                                    </Button>
                                                    <Button href={`${homeDate?.data?.button_url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                                </ButtonGroup>
                                            )}
                                            {mainTheme.layout == 1 ? (
                                                <Button 
                                                    href={`${homeDate?.data?.button_url}`}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:'50px',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:'50px',
                                                    }
                                                    }}>
                                                    <Typography className="btn_text" dangerouslySetInnerHTML={{ __html: homeDate?.data?.button }}/>
                                                </Button>
                                            ) : null}
                                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                <Button 
                                                    href={`${homeDate?.data?.button_url}`}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        '& .ads-click-icon': {
                                                           color: `${themeData?.button_color}`,
                                                        }
                                                    }
                                                    }}>
                                                    {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                                    <Typography className="btn_text" dangerouslySetInnerHTML={{ __html: homeDate?.data?.button }}/>
                                                </Button>
                                            ) : null}
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Box
                                    sx={{
                                        height:{xs:'300px',sm:'300px',md:'480px',lg:'480px'},
                                        backgroundImage:{
                                            xs:`url(${homeDate?.data?.image_medium})`,
                                            sm:`url(${homeDate?.data?.image_medium})`,
                                            md:`url(${homeDate?.data?.image})`,
                                            lg:`url(${homeDate?.data?.image})`
                                        },
                                        // boxShadow:'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
                                        display:'flex',
                                        flexDirection:'column',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundPosition:'center',
                                        backgroundSize:'cover',
                                        backgroundRepeat:'no-repeat',
                                    }}
                                    />
                            </Grid>
                            {homeDate.data.settings==='left' && (
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box 
                                    sx={{
                                        height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'},
                                        display:'flex',
                                        flexDirection:'column',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        px:3,
                                        py:{xs:4,sm:4,md:1,lg:1},
                                        textAlign:'center'
                                    }}>
                                        { homeDate.action == 'show' && (
                                            <Typography 
                                            sx={{textAlign:'center'}}
                                            variant="h4" 
                                            dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                                        )}
                                        <Box sx={{my:1}}>
                                            <Typography variant="h5" sx={{textAlign:'center'}} dangerouslySetInnerHTML={{__html:homeDate.data.box_title}} />
                                        </Box>
                                        <Box sx={{my:1}}>
                                            <Typography variant="h5" sx={{textAlign:'center'}} dangerouslySetInnerHTML={{__html:homeDate.data.description}} />
                                        </Box>
                                        <Box sx={{my:1}}>
                                            {mainTheme?.layout == 5 && (
                                                <ButtonGroup
                                                    disableElevation
                                                    variant="contained"
                                                    aria-label="Disabled button group"
                                                >
                                                    <Button
                                                        sx={{
                                                            my:1.5,
                                                            backgroundColor:`${themeData?.button_color}`,
                                                            color:`${themeData?.button_font_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            fontWeight:600,
                                                            '&:hover': {
                                                            backgroundColor:`${themeData?.button_font_color}`,
                                                            color:`${themeData?.button_color}`,
                                                            border:`1px solid ${themeData?.button_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            }
                                                        }}
                                                        href={`${homeDate?.data?.button_url}`}
                                                    >
                                                        <Typography className="btn_text" dangerouslySetInnerHTML={{ __html: homeDate?.data?.button }}/>
                                                    </Button>
                                                    <Button href={`${homeDate?.data?.button_url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                                </ButtonGroup>
                                            )}
                                            {mainTheme.layout == 1 ? (
                                                <Button 
                                                    href={`${homeDate?.data?.button_url}`}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:'50px',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:'50px',
                                                    }
                                                    }}>
                                                    <Typography className="btn_text" dangerouslySetInnerHTML={{ __html: homeDate?.data?.button }}/>
                                                </Button>
                                            ) : null}
                                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                <Button 
                                                    href={`${homeDate?.data?.button_url}`}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        '& .ads-click-icon': {
                                                           color: `${themeData?.button_color}`,
                                                        }
                                                    }
                                                    }}>
                                                    {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                                    <Typography className="btn_text" dangerouslySetInnerHTML={{ __html: homeDate?.data?.button }}/>
                                                </Button>
                                            ) : null}
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            )}
        </React.Fragment>
        )
    }else{
        return null;
    }
}
export default SplitView;